<template>
    <div class="block w-full">
        <div class="relative" ref="texts">
            <span
                v-for="(text, index) in texts"
                class="textFadeSlider__slide w-full absolute left-0 top-0"
                :class="generateTextClass(index)">{{ text }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name     : 'TextFadeSlider',
        data     : () => {
            return {
                activeIndex   : 0,
                largestWidthPx: 0,
                interval      : null,
            };
        },
        props    : {
            delay: {
                type   : Number,
                default: 2000,
            },
            texts: {
                type   : Array,
                default: () => [],
            },
        },
        created  : function () {
            this.interval = setInterval(this.incrementActiveIndex, this.delay);
        },
        mounted  : function () {
            this.calculateLargestWidth();
        },
        methods  : {
            incrementActiveIndex : function () {
                const nextIndex = this.activeIndex + 1;

                this.activeIndex = this.texts.length === nextIndex
                    ? 0
                    : nextIndex;
            },
            generateTextClass    : function (index) {
                const activeClass = this.activeIndex === index
                    ? 'textFadeSlider__slide--active'
                    : '';

                return `${activeClass}`;
            },
            calculateLargestWidth: function () {
                const texts          = Array.from(this.$refs.texts.children);
                const textWidthsInPx = texts.map(element => element.clientWidth);

                this.largestWidthPx = Math.max.apply(Math, textWidthsInPx);
            },
        },
        destroyed: function () {
            clearInterval(this.interval);
        },
    };
</script>

<style scoped>
    .textFadeSlider__slide {
        opacity: 0;
        transition: opacity ease-out .35s;
    }

    .textFadeSlider__slide--active {
        opacity: 1;
        transition-delay: .75s;
    }
</style>
