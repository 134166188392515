<template>
    <div>
        <div class="container mx-auto h-screen flex items-center justify-center">
            <div class="text-center max-w-base w-full">
                <div class="h-32">
                    <h1>404</h1>
                    <h3>
                        <text-fade-slider
                            :texts="texts">
                        </text-fade-slider>
                    </h3>
                </div>
                <router-link
                    :to="{name: 'home'}"
                    class="button__btn button__btn--secondary">
                    {{ $t('CORE.HOME') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {shuffle} from '@/helpers/ArrayHelpers';
    import TextFadeSlider from '@/components/structure/TextFadeSlider';

    export default {
        name      : 'PageNotFoundError',
        metaInfo() {
            return {
                title: this.$t('META.ERRORS.404.TITLE'),
                meta : [
                    {
                        vmid   : 'robots',
                        name   : 'robots',
                        content: 'noindex,nofollow',
                    },
                ],
            };
        },
        data      : () => {
            return {
                texts: shuffle([
                    'Page not found',
                    'Page non trouvée',
                    'Página no encontrada',
                    'página não encontrada',
                    'pagina non trovata',
                    'Seite nicht gefunden',
                    'ページが見つかりません',
                    '网页未找到',
                    '페이지를 찾을 수 없음',
                ]),
            };
        },
        components: {
            TextFadeSlider,
        },
    };
</script>
